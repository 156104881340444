import { Landing } from "../src/components/Landing/Login/Landing"
import React, { useEffect } from "react"
import type { NextPage } from "next"

const Home: NextPage = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      fetch("/app/auth/api/check-user-authentication")
        .then(res => res.json())
        .then(({ isUserAuthenticated }) => {
          isUserAuthenticated ? (window.location.href = "/app/home") : null
        })
    }
  }, [])
  return <Landing />
}

export default Home

import { NotificationType } from "@kaizen/notification/src/components/GenericNotification"
import { addToastNotification } from "@kaizen/notification"

const createErrorToastNotification = (
  type: NotificationType,
  title: string,
  message: string,
) => {
  return () => {
    addToastNotification({
      type,
      title,
      message,
    })
    const notification = document.querySelector(
      "div[class*=ToastNotificationsList_list]",
    )
    notification?.setAttribute("aria-live", "polite")
  }
}

export const signInErrorToastNotification = createErrorToastNotification(
  "negative",
  "We were not able to sign you in",
  "Sorry, an error from our end prevented you from signing in. Please refresh the page and try again.",
)

export const resetPasswordErrorToastNotification = createErrorToastNotification(
  "negative",
  "We were not able to reset your password",
  "Sorry, an error from our end prevented you from resetting your password. Please refresh the page and try again.",
)

export const googleSignInNotEnabledErrorToastNotification =
  createErrorToastNotification(
    "cautionary",
    "Google sign-in is not enabled for your account",
    "Try logging in using another method.",
  )

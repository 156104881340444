import { EXPIRY_DAYS, PERSISTED_KEY } from "../constants"
import { UserAuthData } from "../types/storage"

/**
 * Adds day(s) to a time value
 *
 * @param days Number of days
 * @param time Time value in milliseconds
 * @returns Time value in milliseconds with added number of days
 */
export function addDays(days: number, time = Date.now()) {
  const date = new Date(time)
  date.setUTCDate(date.getUTCDate() + days)

  return date.getTime()
}

/**
 * Sets client-side data to the local storage
 */
export function setStorage(
  applicationId: string,
  availableLoginOptions: string[],
  lastApplicationLookupTimestamp: number,
  environment: string,
  subdomain: string,
  workEmail: string,
) {
  try {
    window.localStorage?.setItem(
      PERSISTED_KEY,
      JSON.stringify({
        applicationId: applicationId,
        availableLoginOptions: availableLoginOptions,
        lastApplicationLookupTimestamp: lastApplicationLookupTimestamp,
        environment: environment,
        subdomain: subdomain,
        workEmail: workEmail,
        keyExpiry: addDays(EXPIRY_DAYS, lastApplicationLookupTimestamp),
      }),
    )
  } catch (e) {
    console.error(e)
  }
}

/**
 * Removes the key from the local storage
 */
export function removeStorage() {
  try {
    window.localStorage?.removeItem(PERSISTED_KEY)
  } catch (e) {
    console.error(e)
  }
}

/**
 * Retrieves the persisted data from the local storage
 *
 * @returns UserAuthData or null
 */
export function getStorage(): UserAuthData | null {
  try {
    const today = Date.now()
    const persistedData = window.localStorage?.getItem(PERSISTED_KEY)

    if (persistedData) {
      const parsedData = JSON.parse(persistedData) as UserAuthData
      const { keyExpiry } = parsedData

      if (today >= keyExpiry) {
        removeStorage()
        return null
      }

      return parsedData
    }

    return null
  } catch (e) {
    console.error(e)
    return null
  }
}

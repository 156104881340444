import { Container, Content } from "@kaizen/components"
import { Foot } from "./Foot"
import { Head } from "./Head"
import { Main } from "./Main"
import React from "react"

import styles from "../Landing.module.scss"

export const Landing: React.FC = () => {
  return (
    <Container>
      <Content classNameOverride={styles.content}>
        <Head />
        <Main />
        <Foot />
      </Content>
    </Container>
  )
}

import { AUTHENTICATION_SYSTEM } from "../../constants"
import { Button } from "@kaizen/components"
import { DarkGoogleIcon, LightGoogleIcon } from "../Icon"
import { useCsrfTokenContext } from "../../providers"
import Avo from "../../Avo"
import React, { useState } from "react"

interface GoogleSignInProps {
  isPrimary?: boolean
}

export const GoogleSignIn: React.FC<GoogleSignInProps> = ({ isPrimary }) => {
  const primary = isPrimary ?? false
  const { csrfToken } = useCsrfTokenContext()
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (!csrfToken) {
    return null
  }

  const handleGoogleLogin = (csrfToken: string) => {
    setIsSubmitting(true)
    Avo.googleSignInInitiated({ authenticationSystem: AUTHENTICATION_SYSTEM })
    fetch("/app/auth/api/google", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        csrfToken,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        window.location.href = data.url
      })
      .catch(error => {
        console.error("Error: ", error) // eslint-disable-line no-console
        setIsSubmitting(false)
      })
  }

  return (
    <div className="mb-12">
      <Button
        icon={primary ? <LightGoogleIcon /> : <DarkGoogleIcon />}
        label="Sign in with Google"
        fullWidth
        iconPosition="start"
        type="submit"
        primary={primary}
        onClick={() => handleGoogleLogin(csrfToken)}
        disabled={isSubmitting}
      />
    </div>
  )
}

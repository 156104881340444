import React from "react"

export const LightGoogleIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Google">
      <path
        id="Shape"
        d="M6.86589 0.54759C9.07188 -0.199997 11.5468 -0.183862 13.7418 0.606751C14.96 1.04777 16.074 1.7362 17.0288 2.60211C16.7105 2.94094 16.3648 3.25826 16.0356 3.58634C15.41 4.19947 14.7844 4.8126 14.1588 5.42572C13.5387 4.84487 12.7815 4.40384 11.9583 4.15644C10.9925 3.87139 9.94989 3.82836 8.96213 4.03812C7.80975 4.28014 6.73968 4.87176 5.91106 5.70002C5.24158 6.36155 4.73673 7.17906 4.42943 8.0611C3.31546 7.21133 2.20149 6.36693 1.08203 5.51716C2.26185 3.20448 4.38004 1.38123 6.86589 0.54759Z"
        fill="#ffffff"
      />
      <path
        id="Shape_2"
        d="M0.200232 8.0234C0.381321 7.15211 0.677648 6.30234 1.08921 5.50635C2.20318 6.35612 3.31715 7.20052 4.43661 8.05029C3.99761 9.30344 3.99761 10.6964 4.43661 11.9442C3.32264 12.794 2.20867 13.6437 1.0947 14.4881C0.0630438 12.5143 -0.249746 10.1962 0.200232 8.0234Z"
        fill="#ffffff"
      />
      <path
        id="Shape_3"
        d="M10.2047 8.12598C13.4039 8.12598 16.6087 8.12598 19.8079 8.12598C20.1262 9.88469 20.0548 11.7187 19.55 13.4398C19.0835 15.0156 18.2 16.4785 16.9654 17.5918C15.8843 16.7689 14.8033 15.9461 13.7222 15.1232C14.7923 14.4186 15.5496 13.2784 15.7636 12.0306C13.9088 12.0306 12.054 12.0306 10.1992 12.0306C10.2047 10.7291 10.2047 9.42753 10.2047 8.12598Z"
        fill="#ffffff"
      />
      <path
        id="Shape_4"
        d="M1.08398 14.4937C2.19795 13.6493 3.31192 12.7995 4.42589 11.9497C4.85392 13.1813 5.67705 14.2731 6.76358 15.0207C7.44404 15.4886 8.22327 15.8221 9.03542 15.9834C9.83661 16.1448 10.6652 16.1233 11.4719 15.9888C12.2731 15.849 13.0468 15.5639 13.7218 15.1175C14.8028 15.9404 15.8839 16.7633 16.9649 17.5862C15.7961 18.6457 14.3309 19.3664 12.7834 19.716C11.0713 20.0979 9.26041 20.1086 7.55928 19.6622C6.20934 19.3126 4.94172 18.6834 3.8497 17.8282C2.70281 16.9354 1.74798 15.7844 1.08398 14.4937Z"
        fill="#ffffff"
      />
    </g>
  </svg>
)
